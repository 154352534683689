<template>

  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button></ion-back-button>
      </ion-buttons>
      <ion-title>Login with Code</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="confirm" :strong="true">Confirm</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
      <div class="content">
        <div>
          <h1>Login-Code</h1>
          <div>
            <ion-label>Gebe hier deinen Login-Code ein:</ion-label>
          </div>
          <div ref="digit_container">
            <input
              :class="`digit digit-${i}`"
              v-for="i in [0, 1, 2, 3, 4, 5]"
              :onpaste="paste"
              v-bind:key="i"
              placeholder="0"
              :onkeypress="keypress"
              onclick="this.select()"
            />
          </div>
        </div>
      </div>
    </ion-content>
</template>

<style scoped>
.digit {
  font-size: 20px;
  width: 30px;
  text-align: center;
  margin: 30px 3px;
  padding: 3px;
  background-color: var(--ion-color-medium-shade);
  border-radius: 7px;
  display: inline-block;
}

.digit-3 {
  margin-left: 5px;
}

.content > div {
  max-width: 400px;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 15px;
}
</style>

<script setup lang="ts">
import { ref, toRaw } from "vue";
import {
  IonContent,
  IonButton,
  IonLabel,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonTitle,
  modalController,
} from "@ionic/vue";
import * as api from "@/api";
import { useRoute } from "vue-router";
import { watch } from "vue";
import { toRefs } from "vue";


const props = defineProps<{
  hash: string;

}>();


const { hash } = toRefs(props);
watch(hash, (hash) => {
  console.log("CHANGED", hash)
})

console.log("RECEIVED_PROPS", toRaw(props));

const digit_container = ref<HTMLElement | null>();

function getRightSiblings(elmt: HTMLInputElement) {
  if (!elmt.parentElement) {
    return [];
  }
  let children: HTMLInputElement[] = Array.prototype.slice
    .call(elmt.parentElement.children)
    .filter((ev) => ev instanceof HTMLInputElement);
  console.log(children);
  while (children.length && children[0] != elmt) {
    children = children.slice(1);
  }
  return children;
}

function keypress(evt: KeyboardEvent) {
  if (!(evt.target instanceof HTMLInputElement)) {
    return;
  }
  const elmt = evt.target;
  if (evt.which < 48 || evt.which > 57) {
    evt.preventDefault();
    return;
  }
  elmt.select();

  console.log(elmt);
  const children = getRightSiblings(elmt);
  console.log(children);
  setTimeout(() => {
    children[0].setAttribute("disabled", "");
    setTimeout(() => {
      if (children.length > 1) {
        children[1].focus();
        children[1].select();
      } else {
        children[0].focus();
      }
      children[0].removeAttribute("disabled");
      console.log(getCode());
    }, 0);
  }, 0);
}

function paste(evt: ClipboardEvent) {
  evt.preventDefault();
  if (!(evt.target instanceof HTMLInputElement)) {
    return;
  }
  console.log(evt);
  const number: string = evt.clipboardData?.getData("text/plain") || "";
  let siblings = getRightSiblings(evt.target);
  console.log(siblings);
  for (let i = 0; i < number.length && siblings.length; i++) {
    if (number[i] >= "0" && number[i] <= "9") {
      siblings[0].value = number[i];
      siblings = siblings.slice(1);
    }
  }
}

function getCode() {
  if (!digit_container.value) return "000000";
  return Array.prototype.map
    .call(digit_container.value.children, (v) => {
      return v.value.slice(v.value.length - 1) || "0";
    })
    .join("");
}

async function confirm() {
console.log("USING_ORPS", toRaw(props));
  await api.handleLoginLink(getCode(), props.hash);
  await modalController.dismiss(getCode(), 'confirm');
}

</script>
