import { Storage } from "@ionic/storage";
import { DeepReadonly, Ref, UnwrapRef, readonly, ref } from "vue";

export class LoginRequest {
  constructor(
    public uuid: string,
    public expires: number,
  ) {}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isLoginRequest(value: any): value is LoginRequest {
  return (
    value && typeof value.uuid == "string" && typeof value.expires == "number"
  );
}

type AssertNoUndefined<T> = [T] extends [Exclude<T, undefined>]
  ? [Exclude<T, undefined>] extends [T]
    ? T
    : never
  : never;
type SimpleValueType<T> = AssertNoUndefined<UnwrapRef<T>>;
type SimpleValueRef<T> = Ref<SimpleValueType<T> | undefined>;

class SimpleValue<T> {
  private reference: SimpleValueRef<T> = ref(undefined);

  constructor(
    private key: string,
    private storage: Promise<Storage>,
    private guard: (data: unknown) => data is SimpleValueType<T>,
    private default_value: SimpleValueType<T>,
  ) {}

  async asRef(): Promise<DeepReadonly<SimpleValueRef<T>>> {
    await this.get();
    return readonly(this.reference);
  }

  asRawRef(): DeepReadonly<SimpleValueRef<T>> {
    this.get();
    return readonly(this.reference);
  }

  async set(value: SimpleValueType<T>) {
    const storage = await this.storage;
    this.reference.value = value;
    await storage.set(this.key, value);
  }

  async get(): Promise<SimpleValueType<T>> {
    const storage = await this.storage;
    const result = await storage.get(this.key);
    if (this.guard(result)) {
      this.reference.value = result;
    } else {
      this.reference.value = this.default_value;
    }
    return this.reference.value;
  }
}

const isString = (v: unknown): v is string => typeof v == "string";
const isNumber = (v: unknown): v is number => typeof v == "number";
const isBoolean = (v: unknown): v is boolean => typeof v == "boolean";
class GlobalStorage {
  private storage = new Storage().create();

  loginRequest = new SimpleValue(
    "login-request",
    this.storage,
    isLoginRequest,
    null,
  );
  cachedIsStaff = new SimpleValue(
    "cached-is-staff",
    this.storage,
    isBoolean,
    null,
  );
  cachedUserId = new SimpleValue(
    "cached-user-id",
    this.storage,
    isNumber,
    null,
  );
  authToken = new SimpleValue("auth-token", this.storage, isString, null);
  currentProfile = new SimpleValue(
    "current-profile",
    this.storage,
    isNumber,
    null,
  );
  async resetUserData() {
    await this.loginRequest.set(null);
    await this.authToken.set(null);
    await this.loginRequest.set(null);
    await this.currentProfile.set(null);
    await this.cachedUserId.set(null);
    await this.cachedIsStaff.set(null);

  }
}

export const storage = new GlobalStorage();
