import { modalController, toastController } from "@ionic/vue";

import { Camera, CameraResultType, Photo } from "@capacitor/camera";
import LoginModal from "@/views/LoginModal.vue"
import InstallAppPage from "@/views/InstallAppPage.vue"
import { Browser } from "@capacitor/browser";
import * as api from "@/api";
import { WEB_VERSION } from "./config";
import router from "./router";


export async function showUnhandledError(message: string) {
  const toast = await toastController.create({
    message: message,
    duration: 1500,
    position: "bottom",
  });
  await toast.present();
}

function dataURItoBlob(dataURI: string, format: string) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new File([ia], `image.${format}`, { type: mimeString });
}

export async function selectImageURL() {
  const image = await Camera.getPhoto({
    quality: 90,
    // allowEditing: true,
    resultType: CameraResultType.DataUrl,
  });
  return image;
}

export function photoToFile(image: Photo) {
  if (!image.dataUrl) {
    return null;
  }
  return dataURItoBlob(image.dataUrl, image.format);
}

export async function selectImage() {
  const image = await selectImageURL();
  return photoToFile(image);
}

export function formatDate(value?: string) {
  if (!value) {
    return "";
  }
  const now = Date.now();
  const then = Date.parse(value);
  const years = Math.floor((now - then) / 31536000000);
  if (years) {
    return `vor ${years}y`;
  }
  const months = Math.floor((now - then) / 2628000000);
  if (months) {
    return `vor ${months}m`;
  }
  const weeks = Math.floor((now - then) / 604800000);
  if (weeks) {
    return `vor ${weeks}w`;
  }
  const days = Math.floor((now - then) / 86400000);
  if (days) {
    return `vor ${days}d`;
  }
  const hours = Math.floor((now - then) / 3600000);
  if (hours) {
    return `vor ${hours}h`;
  }
  const minutes = Math.floor((now - then) / 60000);
  return `vor ${minutes}min`;
}


let loginModal: HTMLIonModalElement | null = null;
let timer: number | undefined = undefined;

async function redirectIfLoggedIn() {
  if (await api.hasValidCreds()) {
    if(loginModal) {
      console.log("present");
      await modalController.dismiss(null, 'cancel', loginModal.id);
    }
  }
}

export async function ask_for_login() {
  if (WEB_VERSION) {
    router.push("./download-app/");
    return;
  }
  if (loginModal == null) {
    try {
      timer = setInterval(redirectIfLoggedIn, 50);
      loginModal = await modalController.create({
        component: LoginModal,
      });
      console.log("present");
      await loginModal.present();
      console.log("wait for dismiss");
      await loginModal.onDidDismiss();
    } finally {
      console.log("cleanup");
      if (timer !== undefined) {
        clearInterval(timer);
      }
      loginModal = null;
      timer = undefined;
      try {
        await Browser.close();
      } catch {
        // ignore
      }
    }
  }

}

