<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="cancel" color="danger">Abbrechen</ion-button>
      </ion-buttons>
      <!--- <ion-buttons slot="end">
        <ion-button color="primary">Speichern</ion-button>
      </ion-buttons> -->
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <b>Fehler:</b>
    <p>
      {{ value[0][0] }}
    </p>

    <ion-checkbox
      style="width: 100%"
      v-if="value[1].length > 0"
      v-model="details"
      >Show more details</ion-checkbox
    >
    <br />
    <br />
    <b v-if="value[0].length > 1 || details">Gründe:</b>
    <ion-list>
      <ion-item text-wrap v-for="(v, i) in value[0].slice(1)" v-bind:key="i">
        <ion-label class="ion-text-wrap">{{ v }}</ion-label>
      </ion-item>
      <div v-if="details">
        <ion-item text-wrap v-for="(v, i) in value[1]" v-bind:key="i">
          <ion-label class="ion-text-wrap">{{ v }}</ion-label>
        </ion-item>
      </div>
    </ion-list>
  </ion-content>
</template>

<style scoped></style>
<script setup lang="ts">
import { ref } from "vue";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonCheckbox,
  IonLabel,
  IonToolbar,
  modalController,
} from "@ionic/vue";

const props = defineProps<{
  modalValue?: [string[], string[]];
}>();

const details = ref(false);
const value = ref<[string[], string[]]>(props.modalValue || [[], []]);

async function cancel() {
  modalController.dismiss(null);
}
</script>
