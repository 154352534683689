import { isPlatform } from '@ionic/vue';


function parseOrigin(raw_url: string) {
    const url = new URL(raw_url);
    return url.origin
}

export const API_BASE_URL = parseOrigin("https://api.denk-stets-an-mich.de");
export const DEEP_LINK_URL = parseOrigin("https://app.denk-stets-an-mich.de");

const FORCE_WEB_VERSION = undefined;
const IS_MOBILE_APP = (isPlatform("ios") || isPlatform("android")) &&
    (!isPlatform("mobileweb") || isPlatform("capacitor") || isPlatform("cordova"));
export const WEB_VERSION = FORCE_WEB_VERSION === undefined ? !IS_MOBILE_APP : FORCE_WEB_VERSION;

export const VERSION_CODE = 30;
export const VERSION_NAME = "0.0.19";
