const messages = {
  de: {
    network_error:
      "Ein Netzwerkfehler ist aufgetreten. Hat dieses Gerät Zugriff auf das Internet?",

    api: {
      no_login_request: "Für dieses Gerät gibt es keinen Login-Code.",
      login_request_expired: "Der Login-Code ist abgelaufen.",
      no_user_is_logged_in:
        "Es ist noch kein User angemeldet. Bitte melde dich an.",
      unexpected_status: "Der Server hat einen Fehler gemeldet (%{status}).",
      not_valid_json: "Der Server hat nicht mit validem JSON geantwortet.",
      unexpected_data: "Der Server hat mit unerwarteten Daten geantwortet.",

      error_handle_pin:
        "Der Anmeldelink / Anmeldepin konnte leider nicht bearbeitet werden.",
      error_load_userdata:
        "Es ist leider ein Fehler beim lesen deiner Userdaten aufgetreten.",
      error_chng_userdata:
        "Es ist leider ein Fehler beim ändern deiner Userdaten aufgetreten.",

      error_remove_mail:
        "Beim entfernen von '%{email}' ist leider ein Fehler aufgetreten.",
      error_set_primary_mail:
        "Beim setzen von '%{email}' als Primäradresse ist leider ein Fehler aufgetreten.",
      error_disconnect_account:
        "Beim entfernen vom Account '%{id}' ist leider ein Fehler aufgetreten.",
      error_add_mail:
        "Beim hinzufügen von '%{email}' ist leider ein Fehler aufgetreten.",
      error_new_pass:
        "Beim erstellen des Passwortes ist leider ein Fehler aufgetreten.",
      error_chg_pass:
        "Beim ändern des Passwortes ist leider ein Fehler aufgetreten.",
      error_pass_reset_link:
        "Beim versenden des Wiederherstellungslinkes ist leider ein Fehler aufgetreten.",
      error_sent_verification:
        "Beim versenden des Verifizierungsmail ist leider ein Fehler aufgetreten.",

      error_load_cats:
        "Beim laden der Kategorien ist leider ein Fehler aufgetreten.",

      error_load_own_profile:
        "Beim laden deines Profils ist leider ein Fehler aufgetreten.",
      error_load_profile:
        "Beim laden des Profils ist leider ein Fehler aufgetreten.",
      error_chng_profile:
        "Beim speichern des Profils ist leider ein Fehler aufgetreten.",

      error_load_post:
        "Beim laden des Posts ist leider ein Fehler aufgetreten.",
      error_chng_posts:
        "Beim speichern des Posts ist leider ein Fehler aufgetreten.",

      error_load_comments:
        "Beim laden der Kommentare ist leider ein Fehler aufgetreten.",
      error_add_comments:
        "Beim hinzufügen des Kommentars ist leider ein Fehler aufgetreten.",
      error_load_my_profiles:
        "Beim laden deiner Profile ist leider ein Fehler aufgetreten.",
      error_load_profiles:
        "Beim laden der Profile ist leider ein Fehler aufgetreten.",
      error_load_feeds:
        "Beim laden des Feeds ist leider ein Fehler aufgetreten.",
      error_load_posts:
        "Beim laden der Posts ist leider ein Fehler aufgetreten.",

      form_errors_prefix: "Der Server meldet die folgenden Fehler",
    },
  },
};

import { createI18n } from "vue-i18n";
// Create VueI18n instance with options
export const i18n = createI18n({
  locale: "de", // set locale
  fallbackLocale: "de",
  messages, // set locale messages
});
