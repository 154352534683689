<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button color="medium" @click="cancel">Cancel</ion-button>
      </ion-buttons>
      <ion-title>Login</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div class="content">
      <div>
        <h1>Denk stehts an mich.</h1>
        <div>
          <div style="text-align: right">
            <ion-nav-link
              style="display: hidden"
              ref="navLink"
              router-direction="forward"
              :component="LoginCodeEnterModal"
              :component-props="componentProps"
            ></ion-nav-link>
            <ion-button
              :onclick="loginNow"
              class="ion-home color-primary item"

            >
              Jetzt anmelden
            </ion-button>

          </div>
        </div>
      </div>
    </div>
  </ion-content>
</template>

<style scoped>
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>

<script setup lang="ts">
import {
  IonContent,
  IonButton,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonHeader,
  modalController,
  IonNavLink,
} from "@ionic/vue";

import * as api from "../api";

import { Browser } from "@capacitor/browser";
import LoginCodeEnterModal from "./LoginCodeEnterModal.vue";
import { ref } from "vue";
import { toRaw } from "vue";

const navLink = ref<InstanceType<typeof IonNavLink>>();
const componentProps = ref({
  hash: "placeholder"
});

async function loginNow() {
  const [loginUrl, newHash] = await api.createLoginUrl();
  componentProps.value.hash = newHash;
  navLink.value?.$el.click();
  await Promise.all([
   Browser.open({ url: loginUrl, windowName: "_system" })
  ])
}

const cancel = () => modalController.dismiss(null, 'cancel');


</script>
