<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar v-if="!WEB_VERSION" slot="bottom">
        <ion-tab-button
          :disabled="!authToken"
          tab="discover"
          href="/tabs/discover/"
        >
          <ion-icon :icon="eye" />
          <ion-label>Entdecken</ion-label>
        </ion-tab-button>

        <ion-tab-button :disabled="!authToken" tab="feed" href="/tabs/feed/">
          <ion-icon :icon="images" />
          <ion-label>Neuigkeiten</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="profile" href="/tabs/profileTab/">
          <ion-icon :icon="person" />
          <ion-label>Profil</ion-label>
        </ion-tab-button>
        <ion-tab-button v-if="isStaff" tab="admin" href="/tabs/admin/">
          <ion-icon :icon="square" />
          <ion-label>AdminView</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from "@ionic/vue";
import { eye, images, person, square } from "ionicons/icons";
import { storage } from "@/storage";
import { WEB_VERSION } from "@/config";

const authToken = storage.authToken.asRawRef();
const isStaff = storage.cachedIsStaff.asRawRef();

onMounted(async () => {
  await storage.authToken.asRef();
  await storage.cachedIsStaff.asRef();
  console.log(isStaff)
});
</script>
