import { modalController, toastController } from "@ionic/vue";
import router from "./router/index";
import { i18n } from "./locale";
import ErrorReporting from "./components/ErrorReporting.vue";
import LoginModal from "@/views/LoginModal.vue"
import { storage } from "./storage";
import { ask_for_login } from "./utils";

export class AppError extends Error {
  cause?: unknown;

  constructor(
    message?: string,
    options?: ErrorOptions,
    public internal = false,
    public fatal = true,
  ) {
    super(message, options);
    Object.setPrototypeOf(this, AppError.prototype);
    this.cause = options?.cause;
  }

  format_generic(generic?: unknown): string[] {
    if (!generic) {
      return [];
    }
    if (generic instanceof Error) {
      return [`${generic.name}: ${generic.message}`].concat(
        this.format_generic(generic.cause),
      );
    }
    console.log(typeof generic);
    if (typeof generic === "string") {
      console.log("G", generic);
      return [generic];
    }
    return [];
  }

  error_chain(): AppError[] {
    if (this.cause instanceof AppError) {
      return [this as AppError].concat(this.cause.error_chain());
    } else if (this.cause) {
      return [this, new WrappedError(this.cause)];
    } else {
      return [this];
    }
  }

  error_chain_splitted(): [AppError[], AppError[]] {
    const errors = this.error_chain();
    let idx = errors.findIndex((e) => e.internal);
    if (idx == -1) {
      idx = errors.length;
    }
    const result: [AppError[], AppError[]] = [
      errors.slice(0, idx),
      errors.slice(idx),
    ];
    if (result[0].length === 0) {
      result[0] = [
        new WrappedError("Ein unerwarteter interner Fehler ist aufgetreten!"),
      ];
    }
    return result;
  }

  async formatted_chain(): Promise<[string[], string[]]> {
    const [pub, priv] = this.error_chain_splitted();
    return [
      await Promise.all(pub.map((value) => value.formatted())),
      await Promise.all(priv.map((value) => value.formatted())),
    ];
  }

  async formatted(): Promise<string> {
    return this.message;
  }
}

export class WrappedError extends AppError {
  constructor(cause: unknown, internal = false) {
    super("", { cause }, internal);
    Object.setPrototypeOf(this, WrappedError.prototype);
  }

  error_chain(): AppError[] {
    if (this.cause instanceof AppError) {
      return this.cause.error_chain();
    }
    console.log("wrapped", this.cause);
    return [this];
  }

  async formatted(): Promise<string> {
    if (this.cause instanceof AppError) {
      return this.formatted();
    } else {
      console.log("F", this.format_generic(this.cause).join(" | "));
      return this.format_generic(this.cause).join(" | ");
    }
  }
}

export class ContextError extends AppError {
  constructor(
    message?: string,
    public recoverHint?: string,
    options?: ErrorOptions,
    internal = false,
  ) {
    super(message, options, internal);
    Object.setPrototypeOf(this, ContextError.prototype);
  }
}

export class InternalError extends AppError {
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options, true);
    Object.setPrototypeOf(this, APIError.prototype);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* extractAPIErrors(data: any) {
  const length = data?.form?.errors?.length;
  if (typeof length === "number") {
    for (let j = 0; j < length; j++) {
      const error: string | undefined = data?.form?.errors?.at(j);
      if (typeof error == "string") {
        yield error;
      }
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* extractAPIFormErrors(data: any) {
  const fields = data?.form?.fields;
  if (typeof fields == "object") {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const values: any = Object.values(fields);
    for (let i = 0; i < values.length; i++) {
      const label = values[i].label;
      const length = values[i].errors?.length;
      for (let j = 0; j < length; j++) {
        const field_error = values[i].errors?.at(j);
        if (typeof label == "string" && typeof field_error == "string") {
          yield [label, field_error];
        }
      }
    }
  }
}

export class APIError extends AppError {
  constructor(
    public response?: Response | [Response, unknown],
    message?: string,
    options?: ErrorOptions,
    internal = false,
  ) {
    super(message, options, internal);
    Object.setPrototypeOf(this, APIError.prototype);
  }

  async formatted(): Promise<string> {
    return `${this.message} ${await this.extracted()}`;
  }

  async extracted(): Promise<string> {
    let data: unknown = null;
    if (this.response) {
      if ("json" in this.response) {
        data = await this.response.json();
        this.response = [this.response, data];
      } else {
        data = this.response[1];
      }
    }
    console.log("DATA:", data);
    let main_error = null;
    if (
      typeof data === "object" &&
      data &&
      "detail" in data &&
      typeof data.detail === "string"
    ) {
      main_error = data.detail;
    }

    if (
      typeof data === "object" &&
      data &&
      "message" in data &&
      typeof data.message === "string"
    ) {
      main_error = data.message;
    }

    const form_errors = [];
    for await (const message of extractAPIErrors(data)) {
      form_errors.push(message);
    }
    for await (const [field, message] of extractAPIFormErrors(data)) {
      form_errors.push(field + ":" + message);
    }

    if (main_error === null) {
      if (form_errors.length) {
        main_error = i18n.global.t("api.form_errors_prefix");
      } else {
        return "";
      }
    }

    if (form_errors.length) {
      return `${main_error}: \n\n ${form_errors.join("\n")}`;
    } else {
      return `${main_error}`;
    }
  }
}

export class ClientAPIError extends APIError {
  constructor(
    response?: Response | [Response, unknown],
    message?: string,
    options?: ErrorOptions,
  ) {
    super(response, message, options);
    Object.setPrototypeOf(this, ClientAPIError.prototype);
  }
}

export class NetworkError extends APIError {
  constructor(cause?: unknown) {
    super(undefined, i18n.global.t("network_error"), { cause: cause }, false);
    Object.setPrototypeOf(this, NetworkError.prototype);
  }
}

export class UnauthorizedAPIError extends ClientAPIError {
  public asdf = undefined;
  constructor(
    response?: Response | [Response, unknown],
    message?: string,
    options?: ErrorOptions,
  ) {
    super(response, message, options);
    Object.setPrototypeOf(this, UnauthorizedAPIError.prototype);
  }
}

export class ServerAPIError extends APIError {
  constructor(
    response?: Response | [Response, unknown],
    message?: string,
    options?: ErrorOptions,
  ) {
    super(response, message, options, true);
    Object.setPrototypeOf(this, ServerAPIError.prototype);
  }
}

// Returning null is asking the user to retry the called function
export type ErrorReporter = (error: unknown) => Promise<boolean>;

export async function report(error: unknown) {
  console.log("report", error);
  for (let i = 0; i < reporters.length; i++) {
    if (await reporters[i](error)) {
      break;
    }
  }
}

export async function withContext<T>(
  fn: () => Promise<T>,
  message?: string,
  recoverHint?: string,
) {
  try {
    return await fn();
  } catch (e) {
    throw new ContextError(message, recoverHint, { cause: e });
  }
}

async function showUnhandledError(error: unknown): Promise<boolean> {
  const [pub, int] = await new WrappedError(error).formatted_chain();
  const message = pub.join("\n") || "Ein Fehler interner ist aufgetreten!";
  console.log(message);

  const toast = await toastController.create({
    message: `Error: ${message}`,
    layout: "stacked",
    duration: 5000,
    buttons: [
      {
        text: "Details",
        role: "info",
        handler: async () => {
          const modal = await modalController.create({
            component: ErrorReporting,
            componentProps: {
              modalValue: [pub, int],
            },
          });
          await modal.present();
        },
      },
    ],
  });
  toast.present();
  return false;
}

async function handleLogout(error: unknown): Promise<boolean> {
  while (error instanceof Error) {
    if (error instanceof UnauthorizedAPIError) {
      storage.resetUserData();
      await ask_for_login();
      return true;
    }
    error = error.cause;
  }
  return false;
}

export const reporters: ErrorReporter[] = [handleLogout, showUnhandledError];
