import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import TabsPage from "../views/TabsPage.vue";
import * as api from "../api";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { DEEP_LINK_URL, WEB_VERSION } from "@/config";
import { storage } from "@/storage";
import { ask_for_login } from "@/utils";
import { AppError, ContextError, report } from "@/errors";
import { modalController } from "@ionic/vue";


function cancel_or_home(from: RouteLocationNormalized) {
  // if the initial page requires a login we cannot cancel the
  // navigation because the user gets to see a blank page
  // (not even the login popup). This is probably a bug,
  // but also an understandable bug: What is expected to happen
  //  with no initial navigation... Just return to home: We expect
  //  it to not require login.

  // Other than for broken deeplinks this case should not happen anyways.
  // We just want to make sure, that the user does not lands on an empty
  // page without any errors.
  if (from.fullPath != '/') {
    // cancel navigation
    return false;
  }
  // return to home
  return { path: '/' }
}

async function requiresToken(_: RouteLocationNormalized, from: RouteLocationNormalized) {
  if (await storage.authToken.get() == null) {
    ask_for_login();
    return cancel_or_home(from);
  }
  return true;
}
async function requiresTokenOrLinkId(to: RouteLocationNormalized, from: RouteLocationNormalized) {
  if (await storage.authToken.get() == null && !to.query.linkId) {
    ask_for_login();
    return cancel_or_home(from);
  }
  return true;
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/tabs/profileTab/",
  },
  {
    path: "/tabs/",
    component: TabsPage,
    children: [
      {
        path: "",
        redirect: "/tabs/profileTab/",
      },
      {
        path: "admin/",
        component: () => import("@/views/AdminView.vue"),
      },
      {
        path: "profileTab/",
        component: () => import("@/views/ProfileTab.vue"),
      },
      {
        path: ":pathMatch(.*)/download-app/",
        component: () => import("@/views/InstallAppPage.vue"),
      },
      {
        path: "profileTab/profile/:profileId/",
        component: () => import("@/views/ProfilePage.vue"),
        beforeEnter: requiresTokenOrLinkId,
      },
      {
        path: "profileTab/profile/",
        component: () => import("@/views/ProfilePage.vue"),
      },
      {
        path: "profileTab/content/:contentId/",
        component: () => import("@/views/ContentPage.vue"),
        beforeEnter: requiresTokenOrLinkId,
      },
      {
        path: "profileTab/content/",
        component: () => import("@/views/ContentPage.vue"),
        beforeEnter: requiresToken,

      },
      {
        path: "profileTab/account/",
        component: () => import("@/views/AccountPage.vue"),
        beforeEnter: requiresToken,
      },
      {
        path: "profileTab/account/settings/",
        component: () => import("@/views/SettingsPage.vue"),
        beforeEnter: requiresToken,
      },
      {
        path: "profileTab/account/new-profile/",
        component: () => import("@/views/NewProfile.vue"),
        beforeEnter: requiresToken,
      },
      {
        path: "profileTab/account/:accountId/",
        component: () => import("@/views/AccountPage.vue"),
        beforeEnter: requiresToken,
      },
      {
        path: "/login-link/",
        component: () => import("@/views/LoginCodeDisplayPage.vue"),
        beforeEnter: async (to) => {
          if (WEB_VERSION) {
            return;
          }
          try {
            await api.handleLoginLink(
              to.query.ref?.toString(),
              to.query.user_hash?.toString(),
            );
          } catch (e) {
            report(e);
            return { path: "login" };
          }
          return { path: "/" };
        },
      },
      {
        path: "discover/",
        component: () => import("@/views/DiscoverPage.vue"),
        beforeEnter: requiresToken,
      },
      {
        path: "discover/account/:accountId/",
        component: () => import("@/views/AccountPage.vue"),
        beforeEnter: requiresToken,
      },
      {
        path: "discover/profile-link/:linkId/",
        component: () => import("@/views/ProfilePage.vue"),
        beforeEnter: async (to, from) => {
          try {
            const link = await api.fetchProfileLink(to.params.linkId as string);
            if (link.profile === null) {
              report(new ContextError("QR-Code ist noch mit keinem Profil verbunden!"));
              return cancel_or_home(from);
            }
            console.log("YES")
            return {
              path: `/tabs/discover/profile/${link.profile}/`,
              query: {
                linkId: encodeURIComponent(link.link_id),
                entrypoint: 'yes'
              }
            };
          } catch (e) {
            report(e);
            return cancel_or_home(from);
          }
        },
      },
      {
        path: "discover/profile/:profileId/",
        component: () => import("@/views/ProfilePage.vue"),
        beforeEnter: requiresTokenOrLinkId,
      },
      {
        path: "discover/content/:contentId/",
        component: () => import("@/views/ContentPage.vue"),
        beforeEnter: requiresTokenOrLinkId,
      },
      {
        path: "feed/",
        component: () => import("@/views/ContentFeedPage.vue"),
        beforeEnter: requiresToken,
      },
      {
        path: "feed/account/:accountId/",
        component: () => import("@/views/AccountPage.vue"),
        beforeEnter: requiresToken,
      },
      {
        path: "feed/profile/:profileId/",
        component: () => import("@/views/ProfilePage.vue"),
      },
      {
        path: "feed/content/:contentId/",
        component: () => import("@/views/ContentPage.vue"),
        beforeEnter: requiresTokenOrLinkId,
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    beforeEnter: async (to) => {
      if (to.path[to.path.length - 1] != "/") {
        return { path: to.path + "/", query: to.query };
      }
    },
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  strict: true, // applies to all routes
});

export default router;

export function open_deeplink(url: URL) {
  if (!is_deeplink(url)) {
    return false;
  }

  router.push({
    path: url.pathname,
    query: Object.fromEntries(url.searchParams.entries())
  });

  return true;
}

export function is_deeplink(url: URL) {
  return url.host === new URL(DEEP_LINK_URL).host;
}

App.addListener("appUrlOpen", function (event: URLOpenListenerEvent) {
  let url;
  try {
    url = new URL(event.url);
  } catch {
    return;
  }
  open_deeplink(url);
});
