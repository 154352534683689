import { storage, LoginRequest } from "./storage";
import { API_BASE_URL, DEEP_LINK_URL } from "./config";
import {
  ServerAPIError,
  ClientAPIError,
  InternalError,
  UnauthorizedAPIError,
  withContext,
  NetworkError,
} from "./errors";

import { i18n } from "./locale";
import { sha256 } from "js-sha256";

type ObjectFormData = { [key: string]: string };

export class Jingify<T> {
  constructor(public value: T) {}
}

export type FS = "FromServer";
export type TS = "ToServer";
export type TSC = "ToServerCreate";

interface ReadOnly<T> {
  __READONOLY_TYPE__: T;
}
interface HasDefault<T> {
  __HAS_DEFAULT_TYPE__: T;
}

type UnwrapField<T> = T extends ReadOnly<infer X>
  ? UnwrapField<X>
  : T extends HasDefault<infer X>
  ? UnwrapField<X>
  : T;

type IfHasDefault<T> = //
  T extends ReadOnly<infer ROT>
    ? IfHasDefault<ROT>
    : T extends HasDefault<infer RT>
    ? UnwrapField<RT>
    : never;

type IfReadOnly<T> = //
  T extends HasDefault<infer RT>
    ? IfReadOnly<RT>
    : T extends ReadOnly<infer ROT>
    ? UnwrapField<ROT>
    : never;

type HasDefaultFields<T> = keyof {
  [P in keyof T as IfHasDefault<T[P]> extends never ? never : P]: any;
};

type HasNoDefaultFields<T> = keyof {
  [P in keyof T as IfHasDefault<T[P]> extends never ? P : never]: any;
};

type NonReadOnlyFields<T> = keyof {
  [P in keyof T as IfReadOnly<T[P]> extends never ? P : never]: any;
};

type Unwrap<T> = {
  [P in keyof T]: UnwrapField<T[P]>;
};

// VARIATS
type FromServer<T> = Required<Unwrap<T>>;
type ToServer<T> = Partial<Unwrap<Pick<T, NonReadOnlyFields<T>>>>;
type ToServerCreate<T> = Partial<
  Unwrap<Pick<T, HasDefaultFields<T> & NonReadOnlyFields<T>>>
> &
  Required<Unwrap<Pick<T, HasNoDefaultFields<T> & NonReadOnlyFields<T>>>>;
type Variants<D extends FS | TS | TSC, T> = D extends FS
  ? FromServer<T>
  : D extends TS
  ? ToServer<T>
  : ToServerCreate<T>;

// FIELDS
type Either<D extends FS | TS | TSC, F, T> = D extends FS ? F : T;
type Position<D extends TS | FS | TSC = FS> = Either<
  D,
  [number, number],
  Jingify<[number, number]>
>;
type Image<D extends TS | FS | TSC = FS> = Either<
  D,
  string | null,
  Blob | File | null
>;
type ManyToMany<D extends TS | FS | TSC, F> = Either<D, F[], number[]>;
type ForeignKey<D extends TS | FS | TSC, F> = Either<D, F, number>;

export function isImage(image: unknown): image is string {
  return typeof image === "string";
}

function isArrayOf<T>(
  results: unknown,
  guard: (data: unknown) => data is T,
): results is T[] {
  return Array.isArray(results) && results.every((v: unknown) => guard(v));
}

export interface PartialDataList<T> {
  count: number;
  results: T[];
}

export function isPartialDataList<T>(
  data: unknown,
  guard: (data: unknown) => data is T,
): data is PartialDataList<T> {
  return (
    typeof data === "object" &&
    data !== null &&
    "count" in data &&
    typeof data.count === "number" &&
    "results" in data &&
    isArrayOf(data.results, guard)
  );
}

export interface DataList<T> extends PartialDataList<T> {
  offset: number;
}

export function isDataList<T>(
  data: unknown,
  guard: (data: unknown) => data is T,
): data is DataList<T> {
  return (
    isPartialDataList(data, guard) &&
    "offset" in data &&
    typeof data.offset == "object"
  );
}

export interface UserEmail {
  id: number;
  email: string;
  verified: boolean;
  primary: boolean;
}

export function isUserEmail(data: unknown): data is UserEmail {
  return (
    typeof data === "object" &&
    data !== null &&
    "id" in data &&
    typeof data.id === "number" &&
    "email" in data &&
    typeof data.email === "string" &&
    "verified" in data &&
    typeof data.verified === "boolean" &&
    "primary" in data &&
    typeof data.primary === "boolean"
  );
}

export interface SocialAccount {
  id: number;
  provider_id: string;
  provider_name: string;
  name: string;
}

export function isSocialAccount(data: unknown): data is SocialAccount {
  return (
    typeof data === "object" &&
    data !== null &&
    "id" in data &&
    typeof data.id === "number" &&
    "provider_id" in data &&
    typeof data.provider_id === "string" &&
    "provider_name" in data &&
    typeof data.provider_name === "string" &&
    "name" in data &&
    typeof data.name === "string"
  );
}

export type User<D extends TS | FS | TSC = FS> = Variants<
  D,
  {
    username: string;
    is_staff: boolean;
    email: string;
    has_usable_password: ReadOnly<boolean>;
    emailaddress_set: ReadOnly<UserEmail[]>;
    socialaccount_set: ReadOnly<SocialAccount[]>;
  }
> &
  ShortUser<D>;

export function isUser(data: unknown): data is User {
  return (
    typeof data === "object" &&
    data !== null &&
    "id" in data &&
    typeof data?.id === "number" &&
    "username" in data &&
    typeof data?.username === "string" &&
    "email" in data &&
    typeof data?.email === "string" &&
    "has_usable_password" in data &&
    typeof data?.has_usable_password === "boolean" &&
    "is_staff" in data &&
    typeof data?.is_staff === "boolean" &&
    "emailaddress_set" in data &&
    isArrayOf(data?.emailaddress_set, isUserEmail) &&
    "socialaccount_set" in data &&
    isArrayOf(data?.socialaccount_set, isSocialAccount)
  );
}

export type ShortUser<D extends TS | FS | TSC = FS> = Variants<
  D,
  {
    id: ReadOnly<number>;
    first_name: string;
    last_name: string;
    position: Position<D>;
    image: Image<D>;
  }
>;

export function isShortUser(data: unknown): data is ShortUser {
  return (
    typeof data === "object" &&
    data !== null &&
    "id" in data &&
    typeof data?.id === "number" &&
    "first_name" in data &&
    typeof data?.first_name === "string" &&
    "last_name" in data &&
    typeof data?.last_name === "string" &&
    "image" in data &&
    (data.image === null || isImage(data.image))
  );
}

export interface ProfileCategory {
  id: number;
  title: string;
  icon: string;
}

export function isProfileCategory(data: unknown): data is ProfileCategory {
  return (
    typeof data === "object" &&
    data !== null &&
    "id" in data &&
    typeof data.id === "number" &&
    "title" in data &&
    typeof data.title === "string" &&
    "icon" in data &&
    typeof data.icon === "string"
  );
}

export type ShortProfile<D extends TS | FS | TSC = FS> = Variants<
  D,
  {
    id: ReadOnly<number>;
    first_name: string;
    last_name: string;
    owner: ReadOnly<ShortUser>;
    categories: ManyToMany<D, ProfileCategory>;
    image: Image<D>;
  }
>;

export function isShortProfile(data: unknown): data is ShortProfile {
  return (
    typeof data === "object" &&
    data !== null &&
    "id" in data &&
    typeof data.id === "number" &&
    "owner" in data &&
    isShortUser(data.owner) &&
    "categories" in data &&
    isArrayOf(data.categories, isProfileCategory) &&
    "first_name" in data &&
    typeof data.first_name === "string" &&
    "last_name" in data &&
    typeof data.last_name === "string" &&
    "image" in data &&
    (data.image === null || isImage(data.image))
  );
}
export type Profile<D extends TS | FS | TSC = FS> = Variants<
  D,
  {
    description: string;
    position: Position<D>;
  }
> &
  ShortProfile<D>;

export function isProfile(data: unknown): data is Profile {
  return (
    isShortProfile(data) &&
    "description" in data &&
    typeof data.description === "string"
  );
}

export type ShortContent<D extends TS | FS | TSC = FS> = Variants<
  D,
  {
    id: ReadOnly<number>;
    profile: ForeignKey<D, Profile>;
    image: Image<D>;
    text: string;
    privacy: string;
    is_draft: boolean;
    like: string | null;
    total_comments: ReadOnly<number>;
  }
>;

export function isShortContent(data: unknown): data is ShortContent {
  return (
    typeof data === "object" &&
    data !== null &&
    "id" in data &&
    typeof data.id === "number" &&
    "profile" in data &&
    isShortProfile(data.profile) &&
    "image" in data &&
    typeof (data.image === null || isImage(data.image)) &&
    "text" in data &&
    typeof data.text === "string" &&
    "privacy" in data &&
    typeof data.privacy === "string" &&
    "is_draft" in data &&
    typeof data.is_draft === "boolean" &&
    "like" in data &&
    (data.like === null || typeof data.like === "string") &&
    "total_comments" in data &&
    typeof data.total_comments === "number"
  );
}

export type Content<D extends TS | FS | TSC = FS> = Variants<
  D,
  {
    comments: ReadOnly<PartialDataList<Comment>>;
  }
> &
  ShortContent<D>;

export function isContent(data: unknown): data is Content {
  return (
    isShortContent(data) &&
    "comments" in data &&
    isPartialDataList(data.comments, isComment)
  );
}
export type Comment<D extends TS | FS | TSC = FS> = Variants<
  D,
  {
    id: ReadOnly<number>;
    text: string;
    author: ReadOnly<ShortUser>;
    content: number;
    parent: number | null;
    created_at: ReadOnly<string>;
    comments: ReadOnly<PartialDataList<Comment>>;
  }
>;

export function isComment(data: unknown): data is Comment {
  return (
    typeof data === "object" &&
    data !== null &&
    "id" in data &&
    typeof data.id === "number" &&
    "text" in data &&
    typeof data.text === "string" &&
    "author" in data &&
    isShortUser(data.author) &&
    "content" in data &&
    typeof data.content === "number" &&
    "parent" in data &&
    (data.parent == null || typeof data.parent === "number") &&
    "created_at" in data &&
    typeof data.created_at === "string" &&
    "comments" in data &&
    isPartialDataList(data.comments, isComment)
  );
}

function assertData<T>(
  data: { response: Response; data: unknown },
  guard: (data: unknown) => data is T,
): T {
  if (guard(data.data)) {
    return data.data;
  }
  console.log("Data mismatch ", guard, data);
  throw new ServerAPIError(
    [data.response, data],
    i18n.global.t("api.unexpected_data"),
  );
}

function assertPartialDataList<T>(
  data: { response: Response; data: unknown },
  guard: (data: unknown) => data is T,
): PartialDataList<T> {
  if (isPartialDataList(data.data, guard)) {
    return data.data;
  }
  if (data.data && typeof data.data == "object" && "results" in data.data) {
    console.log("Datalist mismatch ", guard, data);
    if (Array.isArray(data.data?.results)) {
      data.data.results.forEach((element: unknown) => {
        if (!guard(element)) {
          console.log("Data mismatch ", guard, element);
        }
      });
    }
  }
  throw new ServerAPIError(
    [data.response, data],
    i18n.global.t("api.unexpected_data"),
  );
}

async function hashUUID(uuid: string) {
  if (!crypto.subtle) {
    return sha256(uuid);
  }
  const encoder = new TextEncoder();
  const data = encoder.encode(uuid);
  const hashArray = new Uint8Array(await crypto.subtle.digest("SHA-256", data));
  let hash = "";
  for (let i = 0; i < hashArray.length; ++i) {
    hash += hashArray[i].toString(16).padStart(2, "0");
  }
  return hash;
}



function buildFormData(data: ObjectFormData | null = null) {
  if (!data) {
    return null;
  }
  const formData = new FormData();
  Object.entries(data).forEach((entry) => {
    formData.append(entry[0], entry[1]);
  });
  return formData;
}

function buildFormDataFromPartial<T>(data: Partial<T> | null = null) {
  if (!data) {
    return null;
  }
  const formData = new FormData();
  Object.entries(data).forEach((entry) => {
    if (entry[1] instanceof Jingify) {
      formData.append(entry[0], JSON.stringify(entry[1].value));
    } else if (Array.isArray(entry[1])) {
      entry[1].forEach((e) => {
        formData.append(entry[0], String(e));
      });
    } else if (entry[1] instanceof Blob) {
      formData.append(entry[0], entry[1]);
    } else {
      formData.append(entry[0], String(entry[1]));
    }
  });
  return formData;
}

async function buildHeaders(login = true, additional: Record<string, string>) {
  const headers = new Headers({
    // Implicitly tell allauth that we are ajax
    Accept: "application/json",
  });

  if (login) {
    const token = await storage.authToken.get();

    if (token == null) {
      throw new UnauthorizedAPIError(
        undefined,
        i18n.global.t("api.no_user_is_logged_in"),
      );
    }
    headers.append("Authorization", "Token " + token);
  }

  for (const [k, v] of Object.entries(additional)) {
    headers.append(k, v);
  }
  return headers;
}

async function apiFetch(
  path: string,
  method = "GET",
  formData: FormData | null = null,
  login = true,
  extract_data = true,
  additional_headers: Record<string, string> = {},
): Promise<{ response: Response; data: unknown }> {
  const headers = await buildHeaders(login, additional_headers);

  // Authenticate
  let response;
  try {
    response = await fetch(API_BASE_URL + path, {
      method: method,
      headers: headers,
      body: formData,
    });
  } catch (e) {
    throw new NetworkError(e);
  }

  if (response.status < 200 || response.status >= 300) {
    if (response.status >= 400 && response.status < 500) {
      if (response.status == 401) {
        throw new UnauthorizedAPIError(
          response,
          i18n.global.t("api.no_user_is_logged_in"),
        );
      }
      throw new ClientAPIError(
        response,
        i18n.global.t("api.unexpected_status", { status: response.status }),
      );
    }
    throw new ServerAPIError(
      response,
      i18n.global.t("api.unexpected_status", { status: response.status }),
    );
  }

  try {
    return { response, data: extract_data ? await response.json() : null };
  } catch (e) {
    throw new ServerAPIError(response, i18n.global.t("api.not_valid_json"), {
      cause: e,
    });
  }
}
function generateUUID() { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if(d > 0){//Use timestamp until depleted
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
      } else {//Use microseconds since page-load if supported
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}
export async function createLoginUrl() {
  const uuid = crypto.randomUUID
    ? crypto.randomUUID()
    : generateUUID();
  const hash = await hashUUID(uuid);
  storage.loginRequest.set(new LoginRequest(uuid, Date.now() + 15 * 60 * 1000));
  const redirect_back_url = `${DEEP_LINK_URL}/login-link/?user_hash=${encodeURIComponent(
    hash,
  )}`;
  return [
    `${API_BASE_URL}/accounts/login-link/?next=${encodeURIComponent(
      redirect_back_url,
    )}` + `&user_hash=${encodeURIComponent(hash)}`,
    hash,
  ];
}

async function findLoginRequestUUID(user_hash: string) {
  const login_request = await storage.loginRequest.get();
  if (!login_request) {
    throw new ClientAPIError(undefined, i18n.global.t("api.no_login_request"));
  }
  const hash = await hashUUID(login_request.uuid);
  console.log(user_hash, hash);
  if (user_hash != hash) {
    throw new ClientAPIError(undefined, i18n.global.t("api.no_login_request"));
  }
  if (login_request.expires < Date.now()) {
    throw new ClientAPIError(
      undefined,
      i18n.global.t("api.login_request_expired"),
    );
  }
  return login_request.uuid;
}

async function doHandleLoginLink(
  ref: string | undefined,
  user_hash: string | undefined,
) {
  console.log("doHandleLoginLink", ref, user_hash);
  try {
    if (!ref || !user_hash) {
      throw new InternalError("User link is not valid.");
    }
    const uuid = await findLoginRequestUUID(user_hash);
    const { response, data } = await apiFetch(
      "/accounts/login-link/",
      "POST",
      buildFormData({ ref, uuid }),
      false,
    );
    if (!data || typeof data !== "object" || !("token" in data) || typeof(data.token) !== "string" ) {
      throw new ServerAPIError(response, i18n.global.t("api.unexpected_data"));
    }
    console.log("success", ref, user_hash, data.token);
    const user = await getUser(undefined, data.token);
    await storage.authToken.set(data.token);
    await storage.loginRequest.set(null);
    await storage.cachedUserId.set(user.id);
    await storage.cachedIsStaff.set(user.is_staff);
  } catch (e) {
    doLogoutUser();
    throw e;
  }
}

async function doLogoutUser() {
  try {
    return await apiFetch("/accounts/token-logout/", "POST", null, true, false);
  } finally {
    await storage.resetUserData();
  }
}

async function doRemoveMail(email: string) {
  return await apiFetch(
    "/accounts/email/",
    "POST",
    buildFormData({
      email,
      action_remove: "1",
    }),
  );
}

async function doSetPrimaryMail(email: string) {
  return await apiFetch(
    "/accounts/email/",
    "POST",
    buildFormData({
      email,
      action_primary: "1",
    }),
  );
}

async function doAddEmail(email: string) {
  return await apiFetch(
    "/accounts/email/",
    "POST",
    buildFormData({ email, action_add: "1" }),
  );
}

async function doResendConfirmationMail(email: string) {
  return await apiFetch(
    "/accounts/email/",
    "POST",
    buildFormData({
      email,
      action_send: "1",
    }),
  );
}

async function doDisconnectAccount(id: number) {
  return await apiFetch(
    "/accounts/social/connections/",
    "POST",
    buildFormData({
      account: id.toString(),
    }),
  );
}

async function doAddPassword(password1: string, password2: string) {
  await apiFetch(
    "/accounts/password/set/",
    "POST",
    buildFormData({ password1, password2 }),
  );
}

async function doChangePassword(
  oldpassword: string,
  password1: string,
  password2: string,
) {
  return await apiFetch(
    "/accounts/password/change/",
    "POST",
    buildFormData({
      oldpassword,
      password1,
      password2,
    }),
  );
}

export function handleLoginLink(
  ref: string | undefined,
  user_hash: string | undefined,
  recoverHint?: string,
) {
  return withContext(
    () => doHandleLoginLink(ref, user_hash),
    i18n.global.t("api.error_handle_pin"),
    recoverHint,
  );
}

export function getUser(recoverHint?: string, token?: string) {
  return withContext(
    async () => assertData(await apiFetch("/accounts/user/", "GET", null, token === undefined, true, token !== undefined ? {
      "Authorization": "Token " + token
    } : undefined), isUser),
    i18n.global.t("api.error_load_userdata"),
    recoverHint,
  );
}

export function patchUser(user: User<TS>, recoverHint?: string) {
  return withContext(
    async () =>
      assertData(
        await apiFetch(
          `/accounts/user/`,
          "PATCH",
          buildFormDataFromPartial(user),
        ),
        isUser,
      ),
    i18n.global.t("api.error_chng_userdata"),
    recoverHint,
  );
}

export function getShortUser(id: number, recoverHint?: string) {
  return withContext(
    async () =>
      assertData(await apiFetch(`/accounts/user/${id}/`), isShortUser),
    i18n.global.t("api.error_load_userdata"),
    recoverHint,
  );
}

export function logoutUser() {
  try {
    doLogoutUser();
  } catch {
    return;
  }
}

export function removeMail(email: string, recoverHint?: string) {
  return withContext(
    () => doRemoveMail(email),

    i18n.global.t("api.error_remove_mail", { email: email }),
    recoverHint,
  );
}

export function setPrimaryMail(email: string, recoverHint?: string) {
  return withContext(
    () => doSetPrimaryMail(email),
    i18n.global.t("api.error_set_primary_mail", { email: email }),
    recoverHint,
  );
}

export function addEmail(email: string, recoverHint?: string) {
  return withContext(
    () => doAddEmail(email),
    i18n.global.t("api.error_add_mail", { email: email }),
    recoverHint,
  );
}

export function disconnectAccount(id: number, recoverHint?: string) {
  return withContext(
    () => doDisconnectAccount(id),

    i18n.global.t("api.error_disconnect_account", { id: id }),
    recoverHint,
  );
}

export function addPassword(pw: string, pw2: string, recoverHint?: string) {
  return withContext(
    () => doAddPassword(pw, pw2),
    i18n.global.t("api.error_new_pass"),
    recoverHint,
  );
}

export function changePassword(
  old: string,
  pw: string,
  pw2: string,
  recoverHint?: string,
) {
  return withContext(
    () => doChangePassword(old, pw, pw2),
    i18n.global.t("api.error_chg_pass"),
    recoverHint,
  );
}

export function forgotPassword(email: string, recoverHint?: string) {
  return withContext(
    async () =>
      await apiFetch(
        "/accounts/password/reset/",
        "POST",
        buildFormData({ email }),
      ),
    i18n.global.t("api.error_pass_reset_link"),
    recoverHint,
  );
}

export function resendConfirmationMail(email: string, recoverHint?: string) {
  return withContext(
    () => doResendConfirmationMail(email),
    i18n.global.t("api.error_sent_verification"),
    recoverHint,
  );
}

export function fetchProfileCategories(
  offset: number = 0,
  recoverHint?: string,
) {
  return withContext(
    async () =>
      assertPartialDataList(
        await apiFetch(`/api/category/?offset=${offset}`),
        isProfileCategory,
      ),
    i18n.global.t("api.error_load_cats"),
    recoverHint,
  );
}

export function fetchProfile(
  id: number,
  link?: string,
  recoverHint?: string,
) {
  return withContext(
    async () =>
      assertData(
        await apiFetch(
          `/api/profiles/${id}/`,
          "GET",
          null,
          !link,
          true,
          link
            ? {
                "Profile-Link": link,
              }
            : {},
        ),
        isProfile,
      ),
    i18n.global.t("api.error_load_profile"),
    recoverHint,
  );
}

export function fetchOwnProfile(id: number, recoverHint?: string) {
  return withContext(
    async () =>
      assertData(await apiFetch(`/api/own-profiles/${id}/`), isProfile),
    i18n.global.t("api.error_load_own_profile"),
    recoverHint,
  );
}

export function patchOwnProfile(
  id: number | undefined,
  profile: Profile<TS> | Profile<TSC>,
  recoverHint?: string,
) {
  return withContext(
    async () =>
      assertData(
        await apiFetch(
          id !== undefined ? `/api/own-profiles/${id}/` : "/api/own-profiles/",
          id !== undefined ? "PATCH" : "POST",
          buildFormDataFromPartial(profile),
        ),
        isProfile,
      ),
    i18n.global.t("api.error_chng_profile"),
    recoverHint,
  );
}

export function fetchContent(
  id: number,
  linkId?: string,
  recoverHint?: string,
) {
  return withContext(
    async () =>
      assertData(
        await apiFetch(
          `/api/content/${id}/`,
          "GET",
          null,
          !linkId,
          true,
          linkId
            ? {
                "Profile-Link": linkId,
              }
            : {},
        ),
        isContent,
      ),
    i18n.global.t("api.error_load_post"),
    recoverHint,
  );
}

export function patchContent(
  id: number | undefined,
  content: Content<TS> | Content<TSC>,
  recoverHint?: string,
) {
  return withContext(
    async () =>
      assertData(
        await apiFetch(
          id ? `/api/content/${id}/` : `/api/content/`,
          id ? "PATCH" : "POST",
          buildFormDataFromPartial(content),
        ),
        isContent,
      ),
    i18n.global.t("api.error_chng_posts"),
    recoverHint,
  );
}

export function fetchComments(
  id: number,
  offset: number,
  recoverHint?: string,
) {
  return withContext(
    async () =>
      assertPartialDataList(
        await apiFetch(`/api/content/${id}/comments/?offset=${offset}`),
        isComment,
      ),
    i18n.global.t("api.error_load_comments"),
    recoverHint,
  );
}

export function addComment(comment: Partial<Comment>, recoverHint?: string) {
  return withContext(
    async () =>
      assertData(
        await apiFetch(
          `/api/comment/`,
          "POST",
          buildFormDataFromPartial(comment),
        ),
        isComment,
      ),
    i18n.global.t("api.error_add_comments"),
    recoverHint,
  );
}


export function generateQRCode(recoverHint?: string) {
  return withContext(
    async () =>
      assertData(
        await apiFetch(
          `/api/admin_profile_link/`,
          "POST",
        ),
        isProfileLink,
      ),
    i18n.global.t("api.error_add_comments"),
    recoverHint,
  );
}

export function fetchSubComments(
  id: number,
  offset: number,
  recoverHint?: string,
) {
  return withContext(
    async () =>
      assertPartialDataList(
        await apiFetch(`/api/comments/${id}/comments/?offset=${offset}`),
        isComment,
      ),
    i18n.global.t("api.error_load_comments"),
    recoverHint,
  );
}

export function fetchUserProfiles(id?: number, recoverHint?: string) {
  return withContext(
    async () =>
      assertPartialDataList(
        await apiFetch(
          id ? `/api/user-profiles/${id}/` : `/api/user-profiles/`,
        ),
        isProfile,
      ),
    i18n.global.t("api.error_load_my_profiles"),
    recoverHint,
  );
}

export async function hasValidCreds() {
  if ((await storage.authToken.get()) == null)
    return false;
  try {
    await apiFetch(`/api/user-profiles/`);
  } catch {
    await storage.authToken.set(null);
    return false;
  }
  return true;
}

export function fetchExploreUserProfiles(
  offset?: number,
  recoverHint?: string,
) {
  return withContext(
    async () =>
      assertPartialDataList(
        await apiFetch(`/api/discover/?offset=${offset || 0}`),
        isProfile,
      ),
    i18n.global.t("api.error_load_profiles"),
    recoverHint,
  );
}
export function fetchContentFeed(offset?: number, recoverHint?: string) {
  return withContext(
    async () =>
      assertPartialDataList(
        await apiFetch(`/api/content-feed/?offset=${offset || 0}`),
        isShortContent,
      ),
    i18n.global.t("api.error_load_feeds"),
    recoverHint,
  );
}

export function fetchProfileContent(
  profile_id: number,
  offset: number,
  link?: string,
  recoverHint?: string,
) {
  return withContext(
    async () =>
      assertPartialDataList(
        await apiFetch(
          `/api/profiles/${profile_id}/content/?offset=${offset || 0}`,
          "GET",
          null,
          !link,
          true,
          link
            ? {
                "Profile-Link": link,
              }
            : {},
        ),
        isContent,
      ),
    i18n.global.t("api.error_load_posts"),
    recoverHint,
  );
}

export async function uploadContentProfile(
  content: Partial<ShortContent>,
  image: File,
) {
  const formData = buildFormDataFromPartial(content);
  formData?.append("is_draft", "true");
  formData?.append("image", image);
  const data = await apiFetch("/api/content/", "POST", formData, true);
  return assertData(data, isContent);
}

export enum ImgDim {
  Sq32 = "/1/32w.webp",
  Sq64 = "/1/64w.webp",
  Sq200 = "/1/200w.webp",
  Sq400 = "/1/400w.webp",
  Sq800 = "/1/800w.webp",
  N800 = "/600w.webp",
}

export function getImageVersion(
  image?: string | null,
  dimension: ImgDim = ImgDim.Sq400,
) {
  if (image) {
    const base_url: string = image;
    const parts = base_url.split(".");
    parts.pop();
    return parts.join(".") + dimension;
  }
  return "";
}

export const LINK_ID_LENGTH = 64;

export type ProfileLink<D extends TS | FS | TSC = FS> = Variants<
  D,
  {
    link_id: ReadOnly<string>;
    profile: number;
  }
>;

export function isProfileLink(data: unknown): data is ProfileLink {
  return (
    typeof data === "object" &&
    data !== null &&
    "profile" in data &&
    (data.profile === null ||
    typeof data.profile === "number") &&
    "link_id" in data &&
    typeof data.link_id === "string"
  );
}

export function fetchProfileLink(link_id: string, recoverHint?: string) {
  return withContext(
    async () =>
      assertData(
        await apiFetch(`/api/profile-link/${link_id}/`, "GET", null, false),
        isProfileLink,
      ),
    i18n.global.t("api.error_load_profile_link"),
    recoverHint,
  );
}

export function patchProfileLink(
  link_id: string,
  link: ProfileLink<TS>,
  recoverHint?: string,
) {
  return withContext(
    async () =>
      assertData(
        await apiFetch(
          `/api/profile-link/${link_id}/`,
          "PATCH",
          buildFormDataFromPartial(link),
        ),
        isProfileLink,
      ),
    i18n.global.t("api.error_patch_profile_link"),
    recoverHint,
  );
}
